import { Box, CardContent, IconButton, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Cell,
  Tooltip,
} from 'recharts'
import {
  IDataTransmitterTable,
  IFilter,
  ITransmitterCSV,
  ITransmitterGraph,
  ITransmitterTable,
} from '../../../domain'
import {
  CardBox,
  CardBoxTitle,
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  TableResponsive,
  TooltipInfo,
  IconCsvDownloader,
  TooltipGraph,
} from '../../../components'
import { ReportService } from '../../../services'
import { formatNumberToScale, formatToLocaleString } from '../../../utils'
import './index.css'

interface DynamicTransmitterRequestsGraphProps {
  dataGraph: ITransmitterGraph
  isLoading: boolean
  isError?: boolean
  activatedFilters: IFilter
}

const headerShowTable = [
  'Endpoint',
  'Total',
  'Sucesso',
  'Erro',
  'Acima do tempo',
]

const headerCSV = [
  { label: 'Endpoint', key: 'endpoint' },
  { label: 'Requisições', key: 'total' },
  { label: 'Sucesso', key: 'success' },
  { label: 'Erro', key: 'error' },
  { label: 'Acima do tempo limite de resposta', key: 'aboveLimit' },
]

export const DynamicTransmitterRequestsGraph = ({
  dataGraph,
  isLoading,
  isError,
  activatedFilters,
}: DynamicTransmitterRequestsGraphProps) => {
  const [loadingTable, setLoadingTable] = useState<boolean>(false)
  const [activeTransmitter, setActiveTransmitter] = useState('')
  const [dataShowTable, setDataShowTable] = useState<IDataTransmitterTable>(
    {} as IDataTransmitterTable,
  )
  const [dataCSV, setDataCSV] = useState<ITransmitterCSV[]>([])

  const handleLabel = (props: any) => {
    return (
      <text
        x={12}
        y={props.y}
        dy={18}
        fill={props.stroke}
        fontSize={16}
        color="#3C3C3C"
        textAnchor="start"
        onClick={() => handleActiveTranstimmer(props.id)}
        cursor="pointer"
      >
        {props.name}
      </text>
    )
  }

  const handleActiveTranstimmer = (id: string) => {
    setActiveTransmitter((prev) => (prev === id ? '' : id))
  }

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography>{payload[0].payload.name}</Typography>
        <Typography>
          Sucesso: {formatToLocaleString(payload[0].payload.success)}
        </Typography>
        <Typography>
          Erro: {formatToLocaleString(payload[0].payload.error)}
        </Typography>
        <Typography>
          Total: {formatToLocaleString(payload[0].payload.total)}
        </Typography>
      </TooltipGraph>
    )
  }

  const handleDataTable = (data: ITransmitterTable) => {
    if (!data.data) {
      setDataShowTable({} as IDataTransmitterTable)
      return
    }

    const tmpData: any = data.data.map((item: IDataTransmitterTable) => [
      item.name,
      formatToLocaleString(item.total),
      formatToLocaleString(item.success),
      formatToLocaleString(item.error),
      formatToLocaleString(item.aboveLimit),
    ])

    setDataShowTable(tmpData)
  }

  const handleDataDownload = (data: ITransmitterTable) => {
    if (!data.data) {
      setDataCSV([])
      return
    }

    const tmp: ITransmitterCSV[] = data.data.map(
      (item: IDataTransmitterTable) => ({
        endpoint: item.name,
        total: formatToLocaleString(item.total),
        success: formatToLocaleString(item.success),
        error: formatToLocaleString(item.error),
        aboveLimit: formatToLocaleString(item.aboveLimit),
      }),
    )

    setDataCSV(tmp)
  }

  const handleTable = () => {
    setLoadingTable(true)

    if (activeTransmitter) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activatedFilters = {
        ...activatedFilters,
        transmitters: [activeTransmitter],
      }
    }

    const controller = new AbortController()
    const signal = controller.signal

    ReportService.getTransmitterTable(activatedFilters, signal)
      .then((data: ITransmitterTable) => {
        handleDataTable(data)
        handleDataDownload(data)
      })
      .catch((error) => {
        if (error.code !== 'ERR_CANCELED') {
          handleDataTable({} as ITransmitterTable)
        }
      })

    return () => {
      controller.abort()
    }
  }

  useEffect(() => {
    handleActiveTranstimmer('')
    handleTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  useEffect(() => {
    handleTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTransmitter])

  useEffect(() => {
    setLoadingTable(true)
  }, [isLoading])

  useEffect(() => {
    setLoadingTable(false)
  }, [dataShowTable])

  return (
    <CardBox>
      <CardBoxTitle
        title="Quantidade de requisições reportadas por sociedade participante Transmissão (Server)"
        action={
          <>
            {!isLoading &&
            !isError &&
            !loadingTable &&
            dataGraph.data &&
            dataGraph.data.length > 0 &&
            dataCSV.length > 0 ? (
              <>
                <TooltipInfo
                  title="Exibe a quantidade de requisições por marca transmissora participante do Open Insurance Brasil"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="info">
                    <InfoOutlined color="info" sx={{ opacity: 0.5 }} />
                  </IconButton>
                </TooltipInfo>
                <TooltipInfo
                  title="Fazer download do arquivo CSV"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="download">
                    <IconCsvDownloader
                      filename="requisicoes-por-seguradora"
                      headers={headerCSV}
                      data={dataCSV}
                    />
                  </IconButton>
                </TooltipInfo>
              </>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataGraph || !dataGraph.data || dataGraph.data.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            sx={{
              display: 'flex',
              gap: { xs: '32px', md: '16px' },
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box
              component="section"
              sx={{
                width: { xs: '100%', md: '50%' },
              }}
            >
              <Box
                component="section"
                sx={{
                  width: '100%',
                  height: { xs: 357, md: 257 },
                  marginBottom: '-14px',
                  overflow: 'hidden scroll',
                }}
              >
                <ResponsiveContainer minHeight={dataGraph.data.length * 35}>
                  <BarChart
                    data={dataGraph.data}
                    margin={{
                      left: -55,
                      right: 6,
                      top: 0,
                      bottom: 6,
                    }}
                    layout="vertical"
                    barGap={10}
                  >
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <CartesianGrid vertical={false} stroke="#f5f5f5" />
                    <XAxis tickMargin={12} type="number" scale="sqrt" hide />
                    <YAxis dataKey="name" type="category" tick={false} />
                    <Bar
                      dataKey="success"
                      stackId="total"
                      barSize={24}
                      isAnimationActive={false}
                      cursor="pointer"
                      onClick={(props) => {
                        handleActiveTranstimmer(props.id)
                      }}
                    >
                      {dataGraph.data.map((entry, index) => {
                        return (
                          <Cell
                            key={`cell-${index}`}
                            fill={'#639B48'}
                            opacity={
                              activeTransmitter !== ''
                                ? activeTransmitter === entry.id
                                  ? 1
                                  : 0.3
                                : 1
                            }
                          />
                        )
                      })}
                    </Bar>
                    <Bar
                      dataKey="error"
                      stackId="total"
                      label={handleLabel}
                      barSize={24}
                      isAnimationActive={false}
                      cursor="pointer"
                      onClick={(props) => {
                        handleActiveTranstimmer(props.id)
                      }}
                    >
                      {dataGraph.data.map((entry, index) => {
                        return (
                          <Cell
                            key={`cell-${index}`}
                            fill={'#E32424'}
                            opacity={
                              activeTransmitter !== ''
                                ? activeTransmitter === entry.id
                                  ? 1
                                  : 0.3
                                : 1
                            }
                          />
                        )
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
              <Box
                component="section"
                sx={{
                  height: '37px',
                  paddingRight: '14px',
                }}
              >
                <ResponsiveContainer>
                  <BarChart
                    data={dataGraph.data}
                    margin={{
                      left: -55,
                      right: 6,
                      top: 0,
                      bottom: 6,
                    }}
                    layout="vertical"
                    barGap={0}
                  >
                    <CartesianGrid vertical={false} stroke="#f5f5f5" />
                    <XAxis
                      tickMargin={12}
                      type="number"
                      scale="sqrt"
                      tickFormatter={(value) =>
                        `${formatNumberToScale(value, 1, 1000)}`
                      }
                    />
                    <YAxis dataKey="name" type="category" tick={false} />
                    <Bar
                      dataKey="success"
                      stackId="total"
                      barSize={0}
                      isAnimationActive={false}
                    />
                    <Bar
                      dataKey="error"
                      stackId="total"
                      barSize={0}
                      isAnimationActive={false}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Box>
            <Box
              component="section"
              sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
                width: { xs: '100%', md: '50%' },
                height: { xs: 380, md: 280 },
                overflow: 'hidden scroll',
              }}
            >
              <TableResponsive
                header={headerShowTable}
                data={dataShowTable}
                isLoading={loadingTable}
              />
            </Box>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
