import { Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { IConsentFunnel, IErrorGraphs, IFilter } from '../../../domain'
import {
  DynamicConsentFunnel,
  DynamicConsentServerFunnel,
} from '../../../components'
import { UserContext } from '../../../context/user'
import { ReportService } from '../../../services'
import { DynamicUpdateTokenAndResources } from 'components/molecules/DynamicUpdateTokenAndResources'

interface DynamicPrivateGraphsProps {
  activatedFilters: IFilter
}

export const DynamicPrivateFunnelGraphs = ({
  activatedFilters,
}: DynamicPrivateGraphsProps) => {
  const { user, loading, updateLoading } = useContext(UserContext)
  const [lastFilter, setLastFilter] = useState<{
    filter: IFilter
    time: number
  }>({
    filter: activatedFilters,
    time: new Date().getTime(),
  })

  const [consentFunnelData, setConsentFunnelData] = useState<IConsentFunnel>(
    {} as IConsentFunnel,
  )
  const [consentServerFunnelData, setConsentServerFunnelData] =
    useState<IConsentFunnel>({} as IConsentFunnel)

  const [updateTokenAndResourcesData, setUpdateTokenAndResourcesData] = useState<IConsentFunnel>(
     {} as IConsentFunnel,
   )

  const [graphsError, setGraphsError] = useState<IErrorGraphs>({
    transmitterGraph: false,
    consentFunnel: false,
    consentServerFunnel: false,
    receiverGraph: false,
    consentGraph: false,
    endpointsGraph: false,
    statusGraph: false,
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (user.name) {
      ReportService.getConsentFunnel(lastFilter.filter, signal)
        .then((data: IConsentFunnel) => {
          setConsentFunnelData(data)
          setGraphsError((prevState) => ({
            ...prevState,
            consentFunnel: false,
          }))
          updateLoading({ name: 'consentFunnel', loading: false })
        })
        .catch((error) => {
          if (error.code && error.code !== 'ERR_CANCELED') {
            setConsentFunnelData({} as IConsentFunnel)
            setGraphsError((prevState) => ({
              ...prevState,
              consentFunnel: true,
            }))
            updateLoading({ name: 'consentFunnel', loading: false })
          }
        })

      ReportService.getConsentServerFunnel(lastFilter.filter, signal)
        .then((data: IConsentFunnel) => {
          setConsentServerFunnelData(data)
          setGraphsError((prevState) => ({
            ...prevState,
            consentServerFunnel: false,
          }))
          updateLoading({ name: 'consentServerFunnel', loading: false })
        })
        .catch((error) => {
          if (error.code && error.code !== 'ERR_CANCELED') {
            setConsentServerFunnelData({} as IConsentFunnel)
            setGraphsError((prevState) => ({
              ...prevState,
              consentServerFunnel: true,
            }))
            updateLoading({ name: 'consentServerFunnel', loading: false })
          }
        })

      ReportService.getUpdateTokenAndResources(lastFilter.filter, signal)
        .then((data: IConsentFunnel) => {
          setUpdateTokenAndResourcesData(data)
          setGraphsError((prevState) => ({
            ...prevState,
            consentFunnel: false,
          }))
          updateLoading({ name: 'tokenAndResources', loading: false })
        })
        .catch((error) => {
          if (error.code && error.code !== 'ERR_CANCELED') {
            setUpdateTokenAndResourcesData({} as IConsentFunnel)
            setGraphsError((prevState) => ({
              ...prevState,
              consentFunnel: true,
            }))
            updateLoading({ name: 'tokenAndResources', loading: false })
          }
        })
    }

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFilter])

  useEffect(() => {
    const now = new Date().getTime()
    if (
      activatedFilters.interval &&
      activatedFilters.interval[0] &&
      (JSON.stringify(activatedFilters) !==
        JSON.stringify(lastFilter?.filter) ||
        Math.abs(lastFilter.time - now) > 500)
    ) {
      setLastFilter({
        filter: activatedFilters,
        time: now,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedFilters])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicConsentFunnel
          dataFunnel={consentFunnelData}
          isLoading={loading.consentFunnel}
          isError={graphsError.consentFunnel}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicUpdateTokenAndResources
          dataFunnelTokenAndResources={updateTokenAndResourcesData}
          isLoading={loading.consentFunnel}
          isError={graphsError.consentFunnel}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <DynamicConsentServerFunnel
          dataFunnel={consentServerFunnelData}
          isLoading={loading.consentServerFunnel}
          isError={graphsError.consentServerFunnel}
        />
      </Grid>
    </Grid>
  )
}
