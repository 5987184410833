import {
  IEndpointGraph,
  IFilter,
  IStatusGraph,
  ITransmitterGraph,
  ISummary,
  ITransmitterTable,
  IConsentGraph,
  IReceiverGraph,
  IClientSummary,
  IConsentFunnel,
  IAvailabilityParticipantsGraph,
} from '../domain'
import axiosClient from '../libs/axios'

interface IParams {
  servers: string
  clients: string
  endpoints: string
  stages: string
  statuscodes: string
  begin: string
  enddate: string
}

export class ReportService {
  static async getSummary(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<ISummary> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/summary', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getClientSummary(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IClientSummary> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/client-summary', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getEndpointChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IEndpointGraph> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/chart1', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getStatusChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IStatusGraph> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/chart2', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getTransmitterChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<ITransmitterGraph> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/chart3', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getTransmitterTable(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<ITransmitterTable> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/table1', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getConsentChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IConsentGraph> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/chart4', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getReceiverChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IReceiverGraph> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/chart5', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getConsentFunnel(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IConsentFunnel> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/chart6', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getConsentServerFunnel(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IConsentFunnel> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/chart7', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getAvailabilityParticipantsChart(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IAvailabilityParticipantsGraph> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }
    
    return axiosClient
      .get('/metrics-management/chart8', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }

  static async getUpdateTokenAndResources(
    filters: IFilter,
    signal?: AbortSignal,
  ): Promise<IConsentFunnel> {
    const optionParams: IParams = {
      servers: filters.parentTransmitters.join(','),
      clients: filters.parentReceivers.join(','),
      endpoints: filters.endpoints.join(','),
      statuscodes: filters.statuses.join(','),
      stages: filters.stages.join(','),
      begin: filters.interval[0],
      enddate: filters.interval[1],
    }

    if (filters.transmitters.length > 0) {
      optionParams.servers = filters.transmitters.join(',')
    }

    if (filters.receivers.length > 0) {
      optionParams.clients = filters.receivers.join(',')
    }

    if (filters.channels.length > 0) {
      optionParams.clients = filters.channels.join(',')
    }

    return axiosClient
      .get('/metrics-management/chart9', {
        params: optionParams,
        signal,
        withCredentials: true,
      })
      .then((response) => response.data)
  }
}
