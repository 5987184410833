import { useEffect, useState } from 'react'
import { IconButton, Popover, TextField } from '@mui/material'
import { DateRange, RangeKeyDict, Range } from 'react-date-range'

import format from 'date-fns/format'
import localePT from 'date-fns/locale/pt'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './index.css'

import { subDays, addDays, startOfWeek, endOfWeek } from 'date-fns'
import { StyledFormControl } from './style'
import { TooltipInfoWide } from '../TooltipInfo'
import { InfoOutlined } from '@mui/icons-material'

interface IDateRangePickerProps {
  id: string
  label: string
  clearCount: number
  disabledRange?: boolean
  isAggregate?: boolean
  onSelect: (id: string, data: string[] | string) => void
}

export const DateRangePicker = ({
  id,
  label,
  clearCount,
  disabledRange,
  isAggregate = false,
  onSelect,
}: IDateRangePickerProps) => {
  const [today] = useState<Date>(new Date())
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element>()
  const [selectQtd, setSelectQtd] = useState(0)
  const [minDate, setMinDate] = useState<Date>()
  const [maxDate, setMaxDate] = useState<Date>()

  const initialStartDate = isAggregate
    ? startOfWeek(subDays(new Date(), 7), { weekStartsOn: 0 })
    : subDays(new Date(), 7)
  const initialEndDate = isAggregate
    ? endOfWeek(subDays(new Date(), 7), { weekStartsOn: 0 })
    : subDays(new Date(), 1)

  const [range, setRange] = useState<Range[]>([
    {
      startDate: initialStartDate,
      endDate: initialEndDate,
      key: 'selection',
    },
  ])

  const handleOpen = (currentTarget: EventTarget) => {
    if (currentTarget) {
      setSelectQtd(0)
      setOpen(true)
      setAnchorEl(currentTarget as Element)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(undefined)
    handleSearch()
  }

  const handleSearch = () => {
    if (selectQtd > 0) {
      onSelect(id, [
        format(range[0].startDate as Date, `yyyy-MM-dd'T'00:00:00'Z'`),
        format(range[0].endDate as Date, `yyyy-MM-dd'T'23:59:59'Z'`),
      ])
      setSelectQtd(0)
    }
  }

  const handleLabel = () => {
    if (open && selectQtd === 1) {
      return `${format(range[0].startDate as Date, 'dd/MM/yyyy')} -`
    }

    return `${format(range[0].startDate as Date, 'dd/MM/yyyy')} - ${format(
      range[0].endDate as Date,
      'dd/MM/yyyy',
    )}`
  }

  useEffect(() => {
    setSelectQtd((prev) => prev + 1)
    const { startDate } = range[0]
    if (disabledRange && startDate) {
      setMaxDate(addDays(startDate, 60))
      setMinDate(subDays(startDate, 60))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range])

  useEffect(() => {
    setSelectQtd(0)
    setMaxDate(undefined)
    setMinDate(undefined)
  }, [open])

  useEffect(() => {
    if (selectQtd === 2) {
      handleClose()
      setMaxDate(undefined)
      setMinDate(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectQtd])

  useEffect(() => {
    onSelect(id, [
      format(range[0].startDate as Date, `yyyy-MM-dd'T'00:00:00'Z'`),
      format(range[0].endDate as Date, `yyyy-MM-dd'T'23:59:59'Z'`),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setRange([
      {
        startDate: initialStartDate,
        endDate: initialEndDate,
        key: 'selection',
      },
    ])

    onSelect(id, [
      format(initialStartDate, `yyyy-MM-dd'T'00:00:00'Z'`),
      format(initialEndDate, `yyyy-MM-dd'T'23:59:59'Z'`),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCount])

  const handleDateChange = (item: RangeKeyDict) => {
    let { startDate, endDate } = item.selection

    if (isAggregate) {
      startDate = startOfWeek(startDate as Date, { weekStartsOn: 0 })
      endDate = endOfWeek(endDate as Date, { weekStartsOn: 0 })
    }

    setRange([{ startDate, endDate, key: 'selection' }])
  }

  return (
    <StyledFormControl size="small">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField
          id={id}
          color="secondary"
          label={label}
          variant="outlined"
          size="small"
          onClick={({ currentTarget }) => handleOpen(currentTarget)}
          value={handleLabel()}
          InputProps={{
            readOnly: true,
          }}
          sx={{
            width: '85%',
            input: {
              color: '#707070',
              letterSpacing: 0,
              cursor: 'pointer',
            },
            fieldset: {
              borderRadius: '8px',
              boxShadow: '0px 3px 6px #00000029',
            },
            '.MuiOutlinedInput-root': {
              height: '51px',
            },
            '&:hover': {
              '&& fieldset': {
                borderColor: 'secondary.main',
                color: '#707070',
              },
            },
          }}
        />
        {isAggregate && (
          <TooltipInfoWide
            title="Selecione um dia no calendário: o intervalo incluirá a semana do dia escolhido (Domingo a Sábado). 
            Para múltiplas semanas, selecione outro dia."
            arrow
            placement="bottom"
            enterTouchDelay={0}
          >
            <IconButton aria-label="info">
              <InfoOutlined color="info" sx={{ opacity: 0.5 }} />
            </IconButton>
          </TooltipInfoWide>
        )}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableScrollLock={true}
      >
        <DateRange
          color="#639b48"
          ranges={range}
          weekStartsOn={0}
          locale={localePT}
          showDateDisplay={false}
          editableDateInputs={true}
          showMonthAndYearPickers={false}
          dragSelectionEnabled={false}
          rangeColors={['#639b48', '#639b48', '#639b48']}
          onChange={handleDateChange}
          months={1}
          disabledDay={(date: Date) => {
            return isAggregate
              ? date > today ||
                  (date >= startOfWeek(today) && date <= endOfWeek(today))
              : date > today
          }}
          maxDate={maxDate}
          minDate={minDate}
        />
      </Popover>
    </StyledFormControl>
  )
}

