import { Box, CardContent, IconButton, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {
  IConsentFunnel,
  IConsentFunnelCSV,
  IDataConsentFunnel,
} from '../../../domain'
import {
  ViewDataEmpty,
  ViewDataError,
  ViewDataLoading,
  CardBox,
  CardBoxTitle,
  IconCsvDownloader,
  TooltipGraph,
  TooltipInfo,
} from '../../../components'
import { formatToLocaleString } from '../../../utils'

interface DynamicConsentFunnelProps {
  dataFunnel: IConsentFunnel
  isLoading?: boolean
  isError?: boolean
}

const headerCSV = [
  { label: 'Etapa', key: 'label' },
  { label: 'Quantidade Server', key: 'server' },
  { label: 'Quantidade Client', key: 'client' },
  { label: 'Quantidade Total', key: 'total' },
]

export const DynamicConsentFunnel = ({
  dataFunnel,
  isLoading,
  isError,
}: DynamicConsentFunnelProps) => {
  const [dataShow, setDataShow] = useState<IConsentFunnel>({} as IConsentFunnel)
  const [dataCSV, setDataCSV] = useState<IConsentFunnelCSV[]>([])
  const [hiddenYAxis, setHiddenYAxis] = useState<boolean>(false)
  const [maxDomainXAsis, setMaxDomainXAsis] = useState<number>(0)
  const [activeLegend, setActiveLegend] = useState<string>('')

  const handleActiveLegend = ({ dataKey }: any) => {
    setActiveLegend(dataKey)
  }

  const handleDisableLegend = () => {
    setActiveLegend('')
  }

  const CustomServerLabelList = ({ x, y, width, name, value }: any) => {
    const data = dataShow.data.find(
      (el: IDataConsentFunnel) => el.name === name,
    )

    if (
      (data?.client === 0 && data.server === 0) ||
      (data?.server as number) < 0
    ) {
      return (
        <text
          x={x + width / 2}
          y={y + 18.5}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {formatToLocaleString(value)}
        </text>
      )
    }

    return (
      <text
        x={x + width / 2 - 18.5}
        y={y + 18.5}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    )
  }

  const CustomClientLabelList = ({ x, y, width, name, value }: any) => {
    const data = dataShow.data.find(
      (el: IDataConsentFunnel) => el.name === name,
    )

    if (
      (data?.client === 0 && data.server === 0) ||
      (data?.client as number) > 0
    ) {
      return (
        <text
          x={x + width / 2}
          y={y + 18.5}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {formatToLocaleString(value)}
        </text>
      )
    }

    return (
      <text
        x={x + width / 2 + 18.5}
        y={y + 18.5}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    )
  }

  const CustomTooltip = ({ payload }: any) => {
    if (!payload || !payload[0]) {
      return null
    }

    return (
      <TooltipGraph>
        <Typography>Etapa: {payload[0].payload.label}</Typography>
        <Typography>
          Quantidade Server: {formatToLocaleString(-payload[0].payload.server)}
        </Typography>
        <Typography>
          Quantidade Client: {formatToLocaleString(payload[0].payload.client)}
        </Typography>
        <Typography>
          Quantidade Total: {formatToLocaleString(payload[0].payload.total)}
        </Typography>
      </TooltipGraph>
    )
  }

  const customScale = (value: number, min: number, max: number) => {
    const scaleFactor = 1 / Math.log(max - min + 1)
    return Math.log(value - min + 1) * scaleFactor
  }

  const handleDataShow = (data: IConsentFunnel) => {
    if (
      !data.data ||
      data.data.length === 0 ||
      data.data.find((el) => (el.total as number) > 0) === undefined
    ) {
      setDataShow({} as IConsentFunnel)
      return
    }

    const maxValueClient = data.data
      .filter((obj) => obj.client !== undefined)
      .sort((a, b) => (b.client ?? 0) - (a.client ?? 0))

    const maxValueServer = data.data
      .filter((obj) => obj.server !== undefined)
      .sort((a, b) => (b.server ?? 0) - (a.server ?? 0))

    let maxClient: number = maxValueClient[0].client
      ? maxValueClient[0].client
      : 0
    let maxServer: number = maxValueServer[0].server
      ? maxValueServer[0].server
      : 0

    if (maxClient > maxServer) {
      setMaxDomainXAsis(maxClient)
    } else {
      setMaxDomainXAsis(maxServer)
    }

    const tmp: IDataConsentFunnel[] = data.data.map(
      (item: IDataConsentFunnel) => ({
        ...item,
        server: -(item.server as number),
      }),
    )

    setDataShow({ ...data, data: tmp })
  }

  const handleDataDownload = (data: IConsentFunnel) => {
    if (!data.data) {
      setDataCSV([])
      return
    }

    const tmp: IConsentFunnelCSV[] = data.data.map(
      (item: IDataConsentFunnel) => ({
        label: item.label,
        server: item.server,
        client: item.client,
        total: item.total,
      }),
    )

    setDataCSV(tmp)
  }

  useEffect(() => {
    handleDataShow(dataFunnel)
    handleDataDownload(dataFunnel)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFunnel])

  useEffect(() => {
    setHiddenYAxis(window.innerWidth <= 899)

    window.addEventListener('resize', () =>
      setHiddenYAxis(window.innerWidth <= 899),
    )

    return () =>
      window.removeEventListener('resize', () =>
        setHiddenYAxis(window.innerWidth <= 899),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CardBox>
      <CardBoxTitle
        title="Funil de Consentimentos - Etapas Server e Client"
        action={
          <>
            {!isLoading && !isError && dataShow.data && dataCSV.length > 0 ? (
              <>
                <TooltipInfo
                  title="Exibe a quantidade de eventos que ocorrem em Server, Client e foram reportados ao PCM"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="info">
                    <InfoOutlined color="info" sx={{ opacity: 0.5 }} />
                  </IconButton>
                </TooltipInfo>
                <TooltipInfo
                  title="Fazer download do arquivo CSV"
                  arrow
                  placement="left-start"
                  enterTouchDelay={0}
                >
                  <IconButton aria-label="download">
                    <IconCsvDownloader
                      filename="funil-consentimentos-server-client"
                      headers={headerCSV}
                      data={dataCSV}
                    />
                  </IconButton>
                </TooltipInfo>
              </>
            ) : (
              ''
            )}
          </>
        }
      />

      <CardContent>
        {isLoading ? (
          <ViewDataLoading />
        ) : isError ? (
          <ViewDataError />
        ) : !dataShow || !dataShow.data || dataShow.data.length === 0 ? (
          <ViewDataEmpty />
        ) : (
          <Box
            component="section"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: { xs: 380, md: 280 },
            }}
          >
            <ResponsiveContainer>
              <BarChart
                data={dataShow.data.map((item) => ({
                  ...item,
                  valueServer: -customScale(
                    item.server ? -item.server : 0,
                    0,
                    maxDomainXAsis,
                  ),
                  valueClient: customScale(
                    item.client ? item.client : 0,
                    0,
                    maxDomainXAsis,
                  ),
                }))}
                stackOffset="sign"
                layout="vertical"
                margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
              >
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <CartesianGrid vertical={false} stroke="#ffffff" />
                <XAxis
                  axisLine={false}
                  type="number"
                  domain={[-1, 1]}
                  padding={{ left: 10, right: 0 }}
                  hide
                />
                <YAxis
                  dataKey="label"
                  type="category"
                  axisLine={true}
                  tickLine={false}
                  interval={0}
                  width={251}
                  hide={hiddenYAxis}
                />
                <Bar
                  dataKey="valueServer"
                  stackId="stack"
                  isAnimationActive={false}
                  fill="#639B48"
                  barSize={35}
                  opacity={
                    activeLegend !== ''
                      ? activeLegend === 'valueServer'
                        ? 1
                        : 0.3
                      : 1
                  }
                >
                  <LabelList
                    content={CustomServerLabelList}
                    valueAccessor={(item: IDataConsentFunnel) => {
                      return `${-(item.server as number)}`
                    }}
                    fill="#000000de"
                  />
                </Bar>
                <Bar
                  dataKey="valueClient"
                  stackId="stack"
                  isAnimationActive={false}
                  fill="#3498db"
                  barSize={35}
                  opacity={
                    activeLegend !== ''
                      ? activeLegend === 'valueClient'
                        ? 1
                        : 0.3
                      : 1
                  }
                >
                  <LabelList
                    content={CustomClientLabelList}
                    valueAccessor={(item: IDataConsentFunnel) => {
                      return `${item.client as number}`
                    }}
                    fill="#000000de"
                  />
                </Bar>
                <Legend
                  formatter={(value: string) =>
                    `Quantidade ${value.replace('value', '')}`
                  }
                  verticalAlign="top"
                  wrapperStyle={{ marginLeft: !hiddenYAxis ? 132 : 6 }}
                  onMouseEnter={handleActiveLegend}
                  onMouseLeave={handleDisableLegend}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </CardBox>
  )
}
